import { FunctionComponent } from "react";

const TeamContainer: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start py-12 px-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border max-w-full z-[8] text-left text-45xl text-font-color-dark-800 font-palanquin mq800:gap-[17px] mq800:py-5 mq800:px-10 mq800:box-border mq1325:pt-[31px] mq1325:pb-[31px] mq1325:box-border">
      <div className="self-stretch flex flex-col items-start justify-start gap-[44px] mq450:gap-[32px] max-w-full mq800:gap-[22px]">
        <div className="self-stretch flex flex-col items-center justify-center py-0 px-5 gap-[24px]">
          <h1 className="m-0 relative text-inherit tracking-[-0.04em] leading-[100%] font-medium font-inherit mq450:text-19xl mq450:leading-[38px] mq800:text-32xl mq800:leading-[51px]">
            Pays Team
          </h1>
          <div className="hidden relative text-5xl tracking-[-0.03em] leading-[150%] text-darkgray mq450:text-lgi mq450:leading-[29px]">
            "Invest in the Future of Finance"
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[32px] max-w-full text-13xl text-black mq800:gap-[16px]">
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center py-0 pr-0.5 pl-0 gap-[24px] mq800:gap-[16px]">
            <div className="flex flex-col items-center justify-center gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-1@2x.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-1@2x.png"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[12px] mq450:gap-[16px]">
                <div className="font-semibold mq450:text-lgi mq800:text-7xl">
                <a href="https://www.linkedin.com/in/filipbloch/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Filip Błoch
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                Founder & CEO
                </div>
                <div className="hidden self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Subme
                </div>
              </div>
            </div>
            <div className="hidden items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/rosada.svg"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/rosada.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/karol-rosada-ab3aa8306/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Karol Rosada
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                CHRO
                </div>
                <div className=" hidden self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                 
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-2@2x.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-2@2x.png"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/patrycjalisikiewicz/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Patrycja Lisikiewicz
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  COO
                </div>
                <div className="hidden self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Google
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-3@2x.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-3@2x.png"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/wpbula/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Wojciech Buła
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  CTO
                </div>
                <div className="hidden self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Ari10
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px]  mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/malgorzata.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/malgorzata.png"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/ma%C5%82gorzata-wache-a64253109/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Małgorzata Wache
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                 CFO
                </div>
                <div className="hidden self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                PZU TI
                </div>
              </div>
              
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/adam.svg"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/adam.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/adam-larsson-/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Adam Larsson
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  CPO
                </div>
                <div className=" hidden self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Gras&Partners
                </div>
              </div>
            </div>
          </div>

          <h2 className="m-0 self-stretch relative text-[40px] tracking-[-0.04em] leading-[100%] font-medium font-inherit text-font-color-dark-800 text-center mq450:text-[32px] mq450:leading-[24px] mq800:text-13xl mq800:leading-[32px]">
            Advisors
          </h2>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center py-0 pr-0.5 pl-0 gap-[24px] mq800:gap-[16px]">
            <div className="hidden flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-4@2x.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/adam.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[12px] mq450:gap-[16px]">
                <div className="font-semibold mq450:text-lgi mq800:text-7xl">
                <a href="https://www.linkedin.com/in/adam-larsson-/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Adam Larsson
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Advisor
                </div>
                <div className="self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Subme
                </div>
              </div>
            </div>
            
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-5@2x.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/michal.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/mkramarz/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Michał Kramarz
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Advisor
                </div>
                <div className="self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Google
                </div>
              </div>
            </div>
            <div className="hidden items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-6@2x.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/mateusz.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/matt-kara-ari10/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Mateusz Kara
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Advisor
                </div>
                <div className="self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Ari10
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px]  mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/adam2.jpg"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/adam2.jpg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/adam-jenkins/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Adam Jenkins
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Advisor
                </div>
                <div className="self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                PZU TI
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-7@2x.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/bartosz.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/bart-kglegal/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Bartosz Gras
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="self-stretch relative text-5xl tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Advisor
                </div>
                <div className="self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                  Gras&Partners
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq1440:max-w-[230px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/frame-8@2x.png"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/bil.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/bilahmed/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Bil Ahmed
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="text-[24px] tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px]">
                  Advisor
                </div>
                <div className="self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                Microsoft
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq1440:max-w-[230px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/sobkow.svg"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/sobkow.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/pawel-sobkow-114316/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Pawel Sobkow
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="text-[24px] tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px]">
                  Advisor
                </div>
                <div className="self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                PBpay
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start gap-[24px] mq450:gap-[16px] min-w-[225px] mq1440:max-w-[230px] mq450:max-w-[302px]">
              <img
                className="rounded-3xl mq450:hidden self-stretch h-64 relative w-[230px] overflow-hidden shrink-0 object-cover"
                alt=""
                src="/kittel.svg"
              />
              <img
                className="hidden w-full mq450:block rounded-3xl self-stretch h-96 relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/kittel.svg"
              />
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px] mq450:gap-[16px]">
                <div className="relative leading-[45px] font-semibold mq450:text-lgi mq450:leading-[27px] mq800:text-7xl mq800:leading-[36px]">
                <a href="https://www.linkedin.com/in/rafalkittel/" className="m-0 p-0 box-border border-0 outline-none bg-transparent no-underline text-current visited:text-current active:text-current hover:text-current focus:text-current">
                <div className="flex flex-row gap-[8px] justify-center items-center text-center text-[24px] leading-[100%]">Rafal Kittel
                    <img
                    className="h-[24px] w-[24px]"
                    alt=""
                    src="/li-logo.svg"
                    />
                  </div>
                  </a>
                </div>
                <div className="text-[24px] tracking-[-0.03em] leading-[100%] text-darkgray text-center mq450:text-[24px]">
                  Advisor
                </div>
                <div className="self-stretch relative text-[20px] tracking-[-0.03em] leading-[100%]  text-darkgray text-center mq450:text-[24px] mq450:leading-[19px]">
                Paysafe Group
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </section>
  );
};

export default TeamContainer;
